export const createBlogLink = (slug) => `/blog/${slug}/`;

export const resolveBlogCategorySlug = (slug) =>
  slug === 'approvals' ? '/success-stories/' : `/blog/category/${slug}/`;

export const createExcerpt = (description, sliceAt = 120) =>
  `${description.slice(0, sliceAt)}...`;

export const slugify = (str) => {
  if (typeof str === 'string') {
    return (
      str
        .toLowerCase()
        .trim()
        // Remove non-letter characters from the start
        .replace(/^[^a-zA-Z]+/, '')
        // Remove all non-word chars
        .replace(/[^\w\s-]/g, '')
        // Replace whitespace and underscores with a dash
        .replace(/[\s_-]+/g, '-')
        // Remove leading and trailing dashes
        .replace(/^-+|-+$/g, '')
    );
  }
  return '';
};

export const getBreadcrumbs = (location) => {
  const pathParts = location.pathname.split('/').filter(Boolean);

  // Generate breadcrumb parts
  const breadcrumbs = pathParts.map((part, index) => {
    // Reconstruct the path for this part of the breadcrumb
    const path = `/${pathParts.slice(0, index + 1).join('/')}/`;

    // Capitalize the first letter of the breadcrumb part
    const name = part.charAt(0).toUpperCase() + part.slice(1);

    return { path, name };
  });

  // Always include the home page at the start
  breadcrumbs.unshift({ path: '/', name: 'Home' });

  return breadcrumbs;
};

export const getBreadcrumbList = (location) => {
  const breadcrumbs = getBreadcrumbs(location);

  const crumbList = breadcrumbs.map((crumb, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: crumb.name,
    item: crumb.path,
  }));

  return JSON.stringify(crumbList);
};

export const prependStaticURL = (path) =>
  JSON.stringify(`https://www.pandevlaw.com${path}`);
