import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import privacyPolicyImg from '../../assets/img/icons/privacy-policy.svg';

function CtaDeck({
  title = 'Schedule a Consultation with an Attorney Today',
  buttonText = 'Talk to a Lawyer',
}) {
  return (
    <div className="section-schedule mt-5 pt-lg-10 mb-10">
      <div className="container text-center">
        <div className="container-schedule d-lg-flex justify-content-between align-items-center pl-lg-8 pr-lg-8 pt-6 pb-6 pt-md-11 pb-md-11">
          <img className="d-none d-lg-block" src={privacyPolicyImg} alt="" />
          <p className="text-display text-white mb-5 mb-lg-0">{title}</p>
          <Link
            to="/contact/"
            className="btn btn-primary d-block d-md-inline-block button-cta">
            {buttonText}
          </Link>
          <div className="schedule-effect" />
        </div>
      </div>
    </div>
  );
}

CtaDeck.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
};

export default CtaDeck;
