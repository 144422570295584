import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import moment from 'moment';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { DEFAULT_DATE_FORMAT } from '../../data/site-data';

function CommonCard({
  link = '',
  imgSrc = '',
  date = '',
  title = '',
  description = '',
  className = 'col-lg-4 mb-6 mb-lg-0',
  hasReadMoreButton = false,
  smallTitle = false,
  isLazy = false,
}) {
  const renderDescription = () => ({
    __html: description,
  });

  return (
    <div className={className}>
      <p className="text-small text-uppercase text-grey mb-3">
        <strong>{moment(date).format(DEFAULT_DATE_FORMAT)}</strong>
      </p>
      {imgSrc && (
        <Link to={link}>
          <GatsbyImage
            className={classNames('img-fluid mb-4 ', {
              lazy: isLazy,
            })}
            image={getImage(imgSrc)}
            alt={title}
            style={{
              width: '100%',
              height: 'auto',
              aspectRatio: '16 / 9',
            }}
          />
        </Link>
      )}

      <h3
        className={classNames('text-headline mb-5 mb-md-3', {
          'text-title-20px': smallTitle,
        })}>
        <Link to={link}>{title}</Link>
      </h3>
      <Link
        to={link}
        className="text-body no-hover-link"
        dangerouslySetInnerHTML={renderDescription()}
      />
      {hasReadMoreButton && (
        <Link to={link} className="mt-3">
          Read more
        </Link>
      )}
    </div>
  );
}

CommonCard.propTypes = {
  link: PropTypes.string.isRequired,
  imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
  hasReadMoreButton: PropTypes.bool,
  smallTitle: PropTypes.bool,
};

export default CommonCard;
