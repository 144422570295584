import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import CtaDeck from '../components/common/cta-deck';
import Layout from '../components/layout';
import CommonCard from '../components/common/common-card';
import { createBlogLink } from '../utils';
import AuthorSocialIcons from '../components/blog/author-social-icons';
import AuthorDescription from '../components/blog/author-description';

function SuccessStories({ location, data, pageContext }) {
  const { categoryList } = data;

  const headMeta = {
    title: 'Adrian Pandev | Pandev Law | Immigration Lawyers USA',
    description:
      'As the principal attorney at Pandev Law, I have helped hundreds of foreign individuals and companies successfully navigate their journey to the United States.',
    keywords:
      'immigration law firm USA,immigration lawyers,immigration law services,immigration attorney,immigration legal services',
  };

  return (
    <Layout headMeta={headMeta} location={location}>
      <div className="section pt-0 pb-5 pt-md-10 pb-md-10">
        <StaticImage
          className="img-fluid d-lg-none mb-5"
          src="../assets/img/pandev-photo.jpg"
          alt="Adrian Pandev immigration lawyer USA"
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <StaticImage
                className="img-fluid d-none d-lg-block"
                src="../assets/img/pandev-photo.jpg"
                alt="Adrian Pandev immigration lawyer USA"
              />
            </div>
            <div className="col-lg-7 pl-lg-6">
              <h1 className="text-hero mb-5 mb-md-10">Adrian Pandev</h1>
              <p className="text-lead mb-5 mb-md-6">
                <AuthorDescription />
                <br />
                <br />
                <AuthorSocialIcons />
              </p>
            </div>
          </div>
        </div>
      </div>
      <section id="posts" className="blog">
        <div className="container blog-post">
          <div className="posts-wrapper">
            <div className="row" id="loaded-posts">
              {data.allWpPost.edges.map((edge) => (
                <CommonCard
                  className="col-lg-6 mb-6 mb-lg-8"
                  title={edge.node.title}
                  description={edge.node.excerpt}
                  link={createBlogLink(edge.node.slug)}
                  imgSrc={edge.node?.featuredImage?.node?.localFile || data.fallbackImage}
                  date={edge.node.date}
                  key={edge.node.id}
                />
              ))}
            </div>
          </div>
          {/* <PaginationLinks
							activePageIndex={pageContext.currentPage - 1}
							pageCount={pageContext.numPages}
							pageSubDir={''}
							baseLink={`/blog/author/adrian/`}
						/> */}
        </div>

        <CtaDeck />
      </section>
    </Layout>
  );
}

SuccessStories.propTypes = {};

export const query = graphql`
  query AllAuthorBlogsPage($skip: Int!, $limit: Int!, $filter: WpPostFilterInput) {
    allWpPost(limit: $limit, sort: { date: DESC }, filter: $filter, skip: $skip) {
      edges {
        node {
          id: databaseId
          title
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    breakpoints: [750, 1080, 1366, 1920]
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              altText
            }
          }
          slug
          excerpt
        }
      }
    }
    fallbackImage: file(name: { regex: "/statue-liberty/" }) {
      id
      name
      childImageSharp {
        gatsbyImageData(
          breakpoints: [750, 1080, 1366, 1920]
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default SuccessStories;
